<template>
  <main>
    <div class="notificationHeader">
      <p>NOTIFICATIONS</p>
    </div>
    <section class="sectionMenu">
      <b-container class="sectionContainer">
        <b-row style="position: relative">
          <router-link id="goToNotification" :to="{ name: 'AllNotifications' }">
            <button v-b-tooltip.hover title="Go Back" class="backToNotifications">
              <i class="fas fa-arrow-left"></i>
            </button>
          </router-link>
          <!-- <b-form-input
            type="text"
            v-model="value"
            placeholder="Search Notification"
          ></b-form-input>
          <i class="fas fa-search"></i> -->
          <button v-if="$lodash.has(notification, 'is_read')" class="deleteBtn" v-b-tooltip.hover title="Delete"
            @click="removeNotification(notification)">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </button>
          <p class="notificationDate">
            {{ getNotificationDate(notification) }}
          </p>
        </b-row>
      </b-container>
    </section>
    <notifications width="30%" position="bottom left" />
    <div v-if="!$lodash.isEmpty(notification)" class="currentNotification">

      <div v-if="$lodash.has(notification, 'is_read')">
        <div class="p-2"> <span class="notif-header">{{ notification.header }}:</span> {{ notification.subject }} </div>
        <div v-html="bodyContent" class="p-2 mt-1" />
        <div v-if="buttonContent" v-html="buttonContent" class="button-div" />
      </div>

      <!-- meeting planner -->
      <div v-if="$lodash.has(notification, 'slot_details')">
        <div class="backColor p-3">
          <p>
            <router-link :to="{ name: 'standardEvent', params: { eventId: notification.event_details.id } }"
              style="color: #bd9d5f">
              {{ notification.event_details.name }}
            </router-link>
            - Meeting Request from {{ notification.requested_by_details.name }}
          </p>
          <h4>
            <div>{{ $formatDate.getUTCDay(notification.slot_details.date) }} {{
              $formatDate.getUTCDate(notification.slot_details.date) }} {{
    $formatDate.getUTCMonth(notification.slot_details.date) }}</div>
            <div>{{ notification.slot_details.start.slice(0, 5) }} - {{ notification.slot_details.end.slice(0, 5) }}</div>
          </h4>
          <div class="user-details">
            <img :src="notification.requested_by_details.prof_pic" alt="" class="invitation-req-image" />
            <div class="userInfo">
              <router-link
                :to="{ name: 'userProfilePage', params: { customerId: notification.requested_by_details.customer_id } }">
                <span class="userName">
                  {{ notification.requested_by_details.name || "" }}
                </span>

              </router-link>
              <span class="userPosition">
                {{ notification.requested_by_details.job_title || "" }}
              </span>
              <router-link
                :to="{ name: 'companyProfile', params: { companyId: notification.requested_by_details.company_id } }">
                <span class="userCompany">
                  {{ notification.requested_by_details.company || "" }}
                </span>
              </router-link>
            </div>
          </div>
          <p>{{ notification.message }}</p>
          <p>Meeting Location: {{ notification.location }}</p>
        </div>
        <div class="companyChangeFooter">
          <p v-if="notification.is_proceeded && isRefused && isMarkedAsSpam(notification)" class="marked-as-spam"> MEETING
            REFUSED AND MEMBER MARKED AS SPAM
          </p>
          <router-link
            v-else-if="notification.is_proceeded && !isRefused && notification.event_details.display_participants"
            :to="{ name: 'meetingPlanner', params: { eventId: notification.event_details.id } }">
            <button :disabled="!isAvailable(notification.event_details.participant_customers)" class="changeCompanyBtn">Go
              to Meeting Planner
            </button>
          </router-link>
          <div v-else-if="!notification.is_proceeded" class="acceptDeclineButtons">
            <b-button variant="outline-success" :disabled="!isAvailable(notification.event_details.participant_customers)
              || !notification.event_details.display_participants" @click="acceptInvite(notification.id)">
              Accept
            </b-button>
            <b-button variant="outline-danger" :disabled="!isAvailable(notification.event_details.participant_customers)
              || !notification.event_details.display_participants" @click="rejectInvite(notification.id)">
              Refuse
            </b-button>
          </div>
          <button v-else class="changeCompanyBtnDisabled" disabled>Meeting Refused</button>
        </div>
        <b-modal id="mpCongratModal" hide-footer>
          <div class="congrat">
            <p>CONGRATULATIONS!</p>
            <p>Your Meeting has been successfully accepted.</p>
            <router-link :to="{ name: 'meetingPlanner', params: { eventId: notification.event_details.id } }">
              Go to Meeting Planner
            </router-link>
          </div>
          <div style="height:7vh; width:100% ; border-top:1px solid #eee"></div>
        </b-modal>
        <b-modal id="mpRefuseModal" hide-footer size="lg">
          <div class="congrat">
            <p class="refuse-title">The meeting request has been successfully refused.</p>
            <p class="refuse-content">Optionally you can explain to the inviting Member the reason of your refusal.</p>
          </div>
          <div style="width:100%; padding: 0 10px; text-align: right;">
            <b-textarea no-resize v-model="refusalMessage"></b-textarea>
            <b-button @click="sendMessage" variant="info" class="mt-3">
              Send
            </b-button>
          </div>
          <hr>
          <div class="congrat">
            <p>Annoyed by this invitation?</p>
            <p>You can mark the inviting Member as spam. <br>
              This will prevent to receive further meeting invitations for this event.
            </p>
            <b-button variant="outline-danger" @click="addToSpamList(notification.requested_by_details)">
              MARK AS SPAM
            </b-button>
          </div>
        </b-modal>
      </div>

      <!-- usefull -->

      <!-- photos section -->
      <!--
      <div>
        <div class="eventPhotos">
          <b-card header-tag="header" footer-tag="footer">
            <template v-slot:header>
              <p class="headerText">
                12-15 march
              </p>
            </template>
            <b-card-text>
              <img src="../../../assets/default-user-profile-image.png" alt="" />
            </b-card-text>
            <template v-slot:footer>
              <em>Footer Slot</em>
            </template>
          </b-card>
        </div>
      </div> -->

    </div>
    <div v-else class="removedNotification">
      This notification has already been <span> removed! </span>
    </div>
  </main>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import getIDfromURL from '../../../utils/getIDfromURL';
import redirectToMeetingPlanner from '../../../utils/mixins/redirectToMeetingPlanner';

export default {
  name: 'Notification',
  mixins: [redirectToMeetingPlanner],
  data() {
    return {
      notif: null,
      isRefused: false,
      notifType: '',
      meetingPlannerInvitationURL: null,
      value: '',
      notification: [],
      buttonContent: '',
      bodyContent: '',
      refusalMessage: '',
    };
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/fetchUserInfo',
      currentNotification: 'notifications/currentNotification',
    }),
  },
  methods: {
    ...mapActions({
      fetchSingleMPInvitation: 'meetingPlanner/fetchSingleMPInvitation',
      acceptOrRefuseInvitation: 'notifications/acceptOrRefuseInvitation',
      cancelMeetingInvitation: 'notifications/cancelMeetingInvitation',
      getCurrentNotification: 'notifications/getCurrentNotification',
      archivNotification: 'notifications/archivNotification',
      addRemoveSpam: 'user/addRemoveSpam',
      createNewConversation: 'messages/createNewConversation',
      sendMessageViaNodeJSServer: 'messages/sendMessageViaNodeJSServer',
    }),
    getNotificationDate(notification) {
      if (!this.$lodash.isEmpty(notification)) {
        return `${moment(notification.created).format('DD/MM/YYYY')} ${new Date(notification.created).toLocaleTimeString()}`;
      }
      return `${moment().format('DD/MM/YYYY')} ${new Date().toLocaleTimeString()}`;
    },
    modifyContent({ message }) {
      if (!message) return;
      
      if (message.search(/<div /) === -1) 
        this.bodyContent = message.replaceAll('<br>', '');
      else this.bodyContent = message.split('').splice(0, message.search(/<div /)).join('').replaceAll('<br>', '');
      
      if (message.search(/<div /) !== -1) 
        this.buttonContent = message.split('').slice(message.search(/<div /)).join('').replace("target='_blank'", '');
      else
        this.buttonContent = '';
    },
    moment(date) {
      return moment(new Date(date));
    },
    isMarkedAsSpam({ requested_by_details: { customer_id } }) {
      return this.userInfo.spam_list.includes(customer_id);
    },
    async acceptInvite(id) {
      try {
        this.acceptOrRefuseInvitation({ id, approved: true }).then(() => {
          this.$bvModal.show('mpCongratModal');
          this.notification.is_proceeded = true;
          this.isRefused = false;
        });
      } catch (err) {
        console.error(err);
      }
    },
    async rejectInvite(id) {
      try {
        this.acceptOrRefuseInvitation({ id, approved: false }).then(() => {
          this.$bvModal.show('mpRefuseModal');
          this.notification.is_proceeded = true;
          this.isRefused = true;
        });
      } catch (err) {
        console.error(err);
      }
    },
    async addToSpamList({ url, customer_id }) {
      await this.addRemoveSpam({
        customer: url,
        action: 'add',
        customer_id,
      })
        .then(() => this.$bvModal.hide('mpRefuseModal'));
    },
    async removeNotification({ id, user }) {
      await this.archivNotification({
        id, user, is_archived: true,
      }).then(() => {
        this.$notify({
          type: 'info',
          text: 'Successfully Removed!',
        });
        this.$router.push({ name: 'AllNotifications' });
      });
    },
    async sendMessage() {
      if (!this.refusalMessage) return;
      try {
        const { result } = await this.createNewConversation({
          receiver_id: this.notification.requested_by_details.user_id,
        });
        const messageData = {
          chat_room_id: result.chat_room_id,
          content: this.refusalMessage,
          receiver_id: this.notification.requested_by_details.user_id,
          is_online_receiver: false,
        };
        this.sendMessageViaNodeJSServer(messageData);
        this.refusalMessage = '';
        this.$notify({
            group: 'userAlerts',
            title: 'Success',
            text: 'Message sent successfully!',
            type: 'success',
        });
      } catch (err) {
        this.$notify({
          group: 'userAlerts',
          title: 'Warning',
          text: err,
          type: 'warn',
        });
      }
    },
  },

  async mounted() {
    this.notification = this.currentNotification;
    this.modifyContent(this.currentNotification);
    const url = sessionStorage.getItem('notifUrl');
    if (this.$lodash.get(this.currentNotification, 'url') !== url) {
      try {
        if (url) this.notification = await this.getCurrentNotification(url);
        if (!url) this.notification = await this.fetchSingleMPInvitation(getIDfromURL(this.$route.path));
        this.modifyContent(this.notification);
        if (this.$lodash.has(this.notification, 'slot_details')) {
          this.notifType = this.notification.type;
        }
      } catch (error) {
        console.log(error);
      }
    }
    this.isRefused = !this.notification.approved;
  },
};
</script>

<style src="./Notification.css" scoped></style>
<style src="./MobileNotificationDesign.css" scoped></style>
